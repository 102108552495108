(function ($) {
  (function(sr){
    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    var debounce = function (func, threshold, execAsap) {
      var timeout;

      return function debounced () {
        var obj = this, args = arguments;
        function delayed () {
          if (!execAsap) {
            func.apply(obj, args);
          }
          timeout = null;
        }

        if (timeout) {
          clearTimeout(timeout);
        }
        else if (execAsap) {
          func.apply(obj, args);
        }

        timeout = setTimeout(delayed, threshold || 100);
      };
    };
    // smartresize
    $.fn[sr] = function(fn){ return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };
  })('smartresize');

  $('.webform-client-form').attr('novalidate', 'novalidate');
  // Formulier per maand
  $('.form-item-submitted-kies-je-soort-lidmaatschap-betaalwijze-maand-waardoor-lid').eq(1).append($('.webform-component--kies-je-soort-lidmaatschap--betaalwijze-maand--naam-fietshandelaar'));
  $('.form-item-submitted-kies-je-soort-lidmaatschap-betaalwijze-maand-waardoor-lid').eq(1).append($('.webform-component--kies-je-soort-lidmaatschap--betaalwijze-maand--postcode-fietshandelaar'));
  $('.form-item-submitted-kies-je-soort-lidmaatschap-betaalwijze-maand-waardoor-lid').eq(3).append($('.webform-component--kies-je-soort-lidmaatschap--betaalwijze-maand--naam-afdeling'));

  // Formulier per jaar
  $('.form-item-submitted-kies-je-soort-lidmaatschap-betaalwijze-jaar-waardoor-lid').eq(1).append($('.webform-component--kies-je-soort-lidmaatschap--betaalwijze-jaar--naam-fietshandelaar'));
  $('.form-item-submitted-kies-je-soort-lidmaatschap-betaalwijze-jaar-waardoor-lid').eq(1).append($('.webform-component--kies-je-soort-lidmaatschap--betaalwijze-jaar--postcode-fietshandelaar'));
  $('.form-item-submitted-kies-je-soort-lidmaatschap-betaalwijze-jaar-waardoor-lid').eq(3).append($('.webform-component--kies-je-soort-lidmaatschap--betaalwijze-jaar--naam-afdeling'));

    Drupal.behaviors.searchBehavior = {
    attach: function(context, settings){
      $('.searchbar', context).once('search', function(){
        var $searchbar = $(this);
        var $submit = $(this).find('input[type=submit]');
        var $input = $(this).find('input[type=text]');

        $submit.click(function(e) {
          if(!$input.val()) {
            $searchbar.toggleClass('is-open');
            e.preventDefault();
            if($searchbar.hasClass('is-open')) {
              $input.focus();
            }
          }
        });
      });
    }
  };

  Drupal.behaviors.masonryBehavior = {
    attach: function(context, settings){
      $('.node-type-local-department .grid-group', context).once('masonry', function(){
        var $container = $(this);
        $container.imagesLoaded(function() {
          $container.masonry({itemSelector: '.grid'});
        });
      });
    }
  };

  Drupal.behaviors.equalHeightsBehavior = {
    attach: function(context, settings){
      $('body:not(.node-type-local-department)', context).once('equalHeights', function(){
        $(this).imagesLoaded(initMatchHeight);
        function initMatchHeight () {
          $('.infotile').matchHeight();
        }
      });
    }
  };

  Drupal.behaviors.menuToggleBehavior = {
    attach: function(context, settings){
      $('.vertical-list--wide__title', context).once('menuToggle', function(){
        $(this).closest('.vertical-list-wrapper').click(function() {
          $(this).toggleClass('is-open');
        });
      });
    }
  };

  Drupal.behaviors.responsiveVideoBehavior = {
    attach: function(context, settings) {
      $('.video-wrapper', context).once('responsive-video', function() {
        var $videoWrapper = $(this);
        var $video = $(this).find('video');

        if (Modernizr.touch || !Modernizr.video) {
          if (!$video.attr('poster')) {
            return false;
          }
          var posterURL = $video.attr('poster');
          $("<img src='" + posterURL + "'>").insertAfter($video);
          return $video.remove();
        }

        var videoWidth = 1400;
        var videoHeight = 400;

        onViewportResize();
        $(window).resize(onViewportResize);

        function onViewportResize() {
          if(($(window).width() / $('.banner').height()) < (videoWidth / videoHeight)) {
            $videoWrapper.addClass('fill-height');
          } else {
            $videoWrapper.removeClass('fill-height');
          }
        }
      });
    }
  };

  Drupal.behaviors.svgBehavior = {
    attach: function(context, settings){
      if(Modernizr.svg) return false;
      $('img[src*="svg"]', context).once('svg', function(){
       $(this).attr('src', function() {
           return $(this).attr('src').replace('.svg', '.png');
       });
      });
    }
  };

  Drupal.behaviors.externalLinkBehavior = {
    attach: function(context, settings) {
      // $('a[href^="http://"]', context).attr('target', '_blank');
      $('a').each(function() {
         var a = new RegExp('/' + window.location.host + '/');
         if(!a.test(this.href)) {
             $(this).click(function(event) {
                 event.preventDefault();
                 event.stopPropagation();
                 window.open(this.href, '_blank');
             });
         }
      });
    }
  };

  Drupal.behaviors.scrollingHeader = {
    attach: function(context, settings) {
      var waypoint;
      waypoint = new window.Waypoint({
        element: $('.breadcrumb')[0],
        handler: function(direction) {
          var header = $('.header');
          switch (direction) {
            case 'down':
              header.addClass('scrolling');
              break;
            case 'up':
              header.removeClass('scrolling');
              break;
          }
        }
      });

      $(window).smartresize(function() {
        if ($(window).width() > 1024) {
          waypoint.enable();
        } else {
          waypoint.disable();
        }
      }).trigger('resize');
    }
  };

})(jQuery);
